import React, {Fragment} from 'react';
import PageTitle from '../../components/pagetitle/PageTitle';
import Navbar from '../../components/Navbar';
import Logo from '../../images/logo2.png'
import bg from '../../images/blog/roompage.jpg'
import Newslatter from '../../components/Newslatter/Newslatter';
import BlogList from '../../components/BlogList'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import BlogSection from '../../components/BlogSection';

const BlogPageFullwidth =() => {
    return(
        <Fragment>
            <Navbar hclass={'wpo-header-style-3'} Logo={Logo} />
            <PageTitle pageTitle={'Latest News'} pagesub={'Blog'} bg={bg}/> 
            <BlogSection/> 
            {/* <BlogList blLeft={'d-none'} blRight={'col-lg-10 offset-lg-1'}/> */}
            {/* <Newslatter nClass={'section-bg'}/> */}
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default BlogPageFullwidth;

