import React, { Fragment, useState } from 'react';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Collapse from "@mui/material/Collapse";
import { Link } from "react-router-dom";
import './style.css';
import {toast} from "react-toastify";
import { useNavigate } from "react-router-dom";

const islogin = localStorage.getItem('loggedIn');
const menus = [
    {
        id: 1,
        title: 'Home',
        link: '/home',
        // submenu: [
        //     {
        //         id: 11,
        //         title: 'Home style 1',
        //         link: '/home'
        //     },
        //     {
        //         id: 12,
        //         title: 'Home style 2',
        //         link: '/home2'
        //     },
        //     {
        //         id: 13,
        //         title: 'Home style 3',
        //         link: '/home3'
        //     },
        // ]
    },

    // {
    //     id: 2,
    //     title: 'About',
    //     link: '/about',
    // },

    {
        id: 2,
        title: 'Booking',
        link: '/room',
        submenu: [
            {
                id: 21,
                title: 'Rooms',
                link: '/room',
            },
            {
                id: 22,
                title: 'Super Deluxe',
                link: '/room/1'
            },
            {
                id: 23,
                title: 'Family Suite',
                link: '/room/2'
            },
            {
                id: 24,
                title: 'Chalets',
                link: '/room/3'
            },
        ]
    },
    {
        id: 3,
        title: 'Media',
        link: '#',
        submenu: [
            {
                id: 31,
                title: 'Offsite',
                link: '/offsite'
            },
            {
                id: 32,
                title: 'Reviews',
                link: '/review'
            },
            {
                id: 33,
                title: 'Events',
                link: '#'
            },
        ]
    },
    // {
    //     id: 3,
    //     title: 'Amenities',
    //     link: '/amenities',
    //     submenu: [
    //         {
    //             id: 31,
    //             title: 'Amenities',
    //             link: '/amenities'
    //         },
    //         {
    //             id: 32,
    //             title: 'Convergence Hall',
    //             link: '/service-single/3'
    //         },
    //     ]
    // },
    {
        id: 4,
        title: 'Tourist Attractions',
        link: '/destination',
        // submenu: [
        //     {
        //         id: 41,
        //         title: 'Tourist Attraction',
        //         link: '/destination',
        //     },
        //     // {
        //     //     id: 42,
        //     //     title: 'Destination single',
        //     //     link: '/destination-single/1'
        //     // },
        // ]
    },

    {
        id: 5,
        title: 'Review',
        link: '/review',
        // submenu: [
        //     {
        //         id: 51,
        //         title: 'Reviews',
        //         link: '/review'
        //     },
        // ]
    },
    // {
    //     id: 5,
    //     title: 'Pages',
    //     link: '/',
    //     submenu: [
    //         {
    //             id: 51,
    //             title: 'Service',
    //             link: '/service'
    //         },
    //         {
    //             id: 52,
    //             title: 'Service S2',
    //             link: '/service-s2'
    //         },
    //         {
    //             id: 53,
    //             title: 'Service single',
    //             link: '/service-single/1'
    //         },
    //         {
    //             id: 54,
    //             title: 'Cart',
    //             link: '/cart'
    //         },
    //         {
    //             id: 55,
    //             title: 'Checkout',
    //             link: '/checkout'
    //         },
    //         {
    //             id: 56,
    //             title: 'Pricing',
    //             link: '/pricing'
    //         },
    //         {
    //             id: 57,
    //             title: 'Search Result',
    //             link: '/search-result'
    //         },
    //         {
    //             id: 58,
    //             title: '404 Error',
    //             link: '/404'
    //         },
    //         {
    //             id: 59,
    //             title: 'Login',
    //             link: '/login'
    //         },
    //         {
    //             id: 591,
    //             title: 'Register',
    //             link: '/register'
    //         },
    //     ]
    // },

    {
        id: 6,
        title: 'Blog',
        link: '/blog-fullwidth',
        submenu: [
            // {
            //     id: 61,
            //     title: 'Blog',
            //     link: '/blog'
            // },
            // {
            //     id: 62,
            //     title: 'Blog Left sidebar',
            //     link: '/blog-left'
            // },
            {
                id: 61,
                title: 'Blog',
                link: '/blog-fullwidth'
            },
            {
                id: 62,
                title: 'Things To Do In Dapoli',
                link: '/blog-single/1'
            },
            {
                id: 63,
                title: 'Top 15 Tourist Places To Visit in Dapoli',
                link: '/blog-single/2'
            },
            {
                id: 64,
                title: 'A Comprehensive Guide to Finding the Perfect Resort in Dapoli',
                link: '/blog-single/3'
            },
            // {
            //     id: 64,
            //     title: 'Blog single',
            //     link: '/blog-single/1'
            // },
            // {
            //     id: 65,
            //     title: 'Blog single Left sidebar',
            //     link: '/blog-single-left-sidebar/1'
            // },
            // {
            //     id: 66,
            //     title: 'Blog single Left sidebar',
            //     link: '/blog-single-fullwidth/1'
            // },
        ]
    },
    {
        id: 7,
        title: 'Contact',
        link: '/contact',
    },
    (islogin !== "true") ?
        {
            id: 8,
            title: 'User',
            link: '/user',
            submenu: [
                {
                    id: 81,
                    title: 'Login',
                    link: '/login'
                },
                {
                    id: 82,
                    title: 'Register',
                    link: '/register'
                }
            ]
        }
    :
        {
            id: 9,
            title: 'User',
            link: '/user',
            submenu: [
                {
                    id: 91,
                    title: 'My Profile',
                    link: '/profile'
                },
                {
                    id: 92,
                    title: 'Logout',
                    link: '/home'
                },
            ]
        }
    
    


]


const MobileMenu = () => {
    const navigate = useNavigate();
    function route(id) {
        navigate(`/room/${id}`);
        window.location.reload()
        }
    const [openId, setOpenId] = useState(0);
    const [menuActive, setMenuState] = useState(false);

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }
    const LogoutHandler = () => {
        localStorage.clear();
        toast.success("User Logout Successful");
        window.location.reload();
        window.scrollTo(10, 0);
    }

    return (
        <div>
            <div className={`mobileMenu ${menuActive ? "show" : ""}`}>
                <div className="menu-close">
                    <div className="clox" onClick={() => setMenuState(!menuActive)}><i className="ti-close"></i></div>
                </div>

                <ul className="responsivemenu">
                    {menus.map((item, mn) => {
                        return (
                            <ListItem className={item.id === openId ? 'active' : null}  key={mn}>
                                {item.submenu ?
                                    <Fragment>
                                        <p onClick={() => setOpenId(item.id === openId ? 0 : item.id)}>{item.title}
                                          <i className={item.id === openId ? 'fa fa-angle-up' : 'fa fa-angle-down'}></i>
                                        </p>
                                        <Collapse in={item.id === openId} timeout="auto" unmountOnExit>
                                            <List className="subMenu">
                                                <Fragment>
                                                    {item.submenu.map((submenu, i) => {
                                                        return (
                                                            <ListItem key={i}>
                                                                {(submenu.id===21 || submenu.id===22 || submenu.id===23)?
                                                                <Link onClick={() => route(submenu.id-20)} className="active"
                                                                    to={submenu.link}>{submenu.title}</Link>
                                                                    :
                                                                    ((submenu.id===92) ?
                                                                <Link onClick={LogoutHandler} className="active"
                                                                    to={submenu.link}>{submenu.title}</Link>
                                                                    :
                                                                <Link onClick={ClickHandler} className="active"
                                                                    to={submenu.link}>{submenu.title}</Link>)
                                                                }
                                                            </ListItem>
                                                        )
                                                    })}
                                                </Fragment>
                                            </List>
                                        </Collapse>
                                    </Fragment>
                                    : <Link className='active'
                                        to={item.link}>{item.title}</Link>
                                }
                            </ListItem>
                        )
                    })}
                </ul>

            </div>

            <div className="showmenu" onClick={() => setMenuState(!menuActive)}>
                <button type="button" className="navbar-toggler open-btn">
                    <span className="icon-bar first-angle"></span>
                    <span className="icon-bar middle-angle"></span>
                    <span className="icon-bar last-angle"></span>
                </button>
            </div>
        </div>
    )
}

export default MobileMenu;